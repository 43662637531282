<template>
  <div class="poster" v-if="posterList.length > 0">
    <canvas id="myCanvas" ref="myCanvas"
      :style="'top:' + width + 'px;left:' + height + 'px;width: ' + width + 'px;height:' + height + 'px;'" />
    <div class="show-image" :style="posterList.length <= 1 ? 'bottom:0' : ''">
      <div class="flx-center">
        <div class="close-btn" v-if="closeBtn" @click="handleClose()"><van-icon name="clear" /></div>
        <img :src="posterImg" class="image-object" />
        <div class="down-img" @click="downloadImg" v-if="inapp === '1' && posterImg">保存图片到相册</div>
      </div>
    </div>
    <div class="footer-tab" v-if="posterList.length > 1">
      <div v-for="(item, index) in posterList" :class="tabIndex == index ? 'active' : ''" @click="changeBg(index)" :key="index">
        <div>
          <img :src="item.miniImage" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { deliveryMessage } from '../../utils/appUtil'

export default {
  name: "Index",
  props: {
    posterList: {
      type: Array,
      default: []
    },
    inapp: {
      type: String,
      default: ''
    },
    width: {
      type: Number,
      default: 750
    },
    height: {
      type: Number,
      default: 1334
    },
    pagePath: "",
    closeBtn: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      tabIndex: 0,
      posterImg: '',
      qrCode: '' //二维码
    }
  },
  created() {
    this.downloadMaterial();
    this.initFun();
  },
  mounted() {
    this.$toast.loading({ overlay: true, message: "海报生成中...", duration: 0 });
    this.beforeDraw();
  },
  methods: {
    initFun() {
      this.generateQrCode();
    },
    //点击关闭
    handleClose() {
      this.$emit("close")
    },
    //下载素材
    downloadMaterial() {
      let list = this.posterList || [];
      list.forEach(item => {
        let img = new Image();
        img.setAttribute("crossOrigin", "anonymous");
        img.src = item.backgroundImage;
        img.onload = () => {
          item.tempBgImage = img;
        }
      });
    },
    changeBg: function (index) {
      this.tabIndex = index;
      this.beforeDraw();
    },
    beforeDraw() {
      let item = this.posterList[this.tabIndex];
      if (this.qrCode && item.tempBgImage) {
        this.draw(item);
      } else {
        setTimeout(() => {
          this.beforeDraw();
        }, 100);
      }
    },
    draw(item) {
      let width = this.width;
      let height = this.height;
      let canvas = this.$refs.myCanvas;
      canvas.width = width;
      canvas.height = height;
      let ctx = canvas.getContext("2d");
      ctx.drawImage(item.tempBgImage, 0, 0, width, height);//背景图片
      let wxCodeData = item.wxCode;
      ctx.drawImage(this.qrCode, wxCodeData.point[0], wxCodeData.point[1], wxCodeData.width, wxCodeData.width);
      ctx.font = "28px HelveticaNeue";
      item.tempPoster = canvas.toDataURL("image/png");
      this.posterImg = item.tempPoster;
      this.$toast.clear();
    },
    downloadImg: function () {
      let message = { 'action': 'downImg', 'data': { 'url': this.posterImg } };
      deliveryMessage(message);
    },
    generateQrCode: function () {
      let path = '';
      path = this.pagePath;
      this.post('/base/ToolsC/QrCode?', {
        size: 6,
        url: path
      }).then(json => {
        this.$toast.clear();
        if (json.code === 1) {
          let img = new Image();
          img.src = json.response;
          img.onload = () => {
            this.qrCode = img;
          }
        } else {
          console.log('走没走这里');
        }
      });
    }
  },
  watch: {}
}
</script>

<style lang="scss" scoped>
.poster {
  canvas {
    position: fixed;
  }

  .show-image {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 70px;
    display: flex;
    z-index: 100;
    flex-wrap: wrap;
    align-items: center;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.4);

    .image-object {
      max-width: 70%;
      border-radius: 12px;
    }

    .flx-center {
      position: relative;
    }

    .down-img {
      width: 50%;
      height: 35px;
      background: white;
      margin-top: 25px;
      border-radius: 5px;
      text-align: center;
      line-height: 35px;
      font-size: 14px;
      display: inline-block;
    }

    .close-btn {
      position: absolute;
      top: -12px;
      right: calc(15% - 1px);
      color: #666;
      font-size: 32px;
      padding-top: 10px;
    }
  }
}

.footer-tab {
  left: 0;
  right: 0;
  padding: 0 10px;
  position: absolute;
  height: 70px;
  background: white;
  bottom: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  >div {
    border-radius: 8px;
    width: 62px;
    height: 62px;
    margin: 0 2.5px;
    overflow: hidden;
    display: flex;
    align-items: center;

    &.active {
      background: #999999;
    }

    >div {
      border-radius: 8px;
      width: 50px;
      height: 50px;
      overflow: hidden;
      margin: auto;

      img {
        border-radius: 8px;
        width: 50px;
      }
    }
  }
}
</style>