<template>
    <div :class="notPositon ? 'yellowTitle_' : 'yellowTitle'" :id="failed ? 'failed' : ''">
        {{info}}
    </div>
</template>

<script>
    export default {
        name: "yellowTitle",
        props:['info', 'notPositon', 'failed'],
        data() {return{}},
        created() {

        }
    }
</script>

<style scoped>
    #failed {
        background-image: url("http://freesaasofficedata.oss.aliyuncs.com/rolorweb/20230620102642917.png");
    }
    .yellowTitle{
        width: 218px;
        height: 36px;
        background-image: url("http://freesaasofficedata.oss.aliyuncs.com/rolorweb/20230831170820704.png");
        background-repeat: no-repeat;
        color: #fff;
        background-size: 100%;
        font-size: 16px;
        font-weight: bold;
        display: flex;
        justify-content: center;
        line-height: 40px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        top: 0;
        letter-spacing: 3px;
        box-sizing: border-box;
        padding-left: 7px;
    }
    .yellowTitle_{
        width: 218px;
        height: 36px;
        background-image: url("http://freesaasofficedata.oss.aliyuncs.com/rolorweb/20230831170820704.png");
        background-repeat: no-repeat;
        color: #fff;
        background-size: 100%;
        font-size: 16px;
        font-weight: bold;
        line-height: 40px;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        letter-spacing: 3px;
        box-sizing: border-box;
        padding-left: 7px;
    }
</style>